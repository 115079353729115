export const creek_id_map = {
    "als": "Al’s Alley",
    "arena": "Arena",
    "bakers": "Bakersfield",
    "bear-x": "Bear Peak Express",
    "big-bear": "Big Bear",
    "buddy": "Buddy’s Way",
    "bunks": "Bunks Bank",
    "capride": "Cab Ride",
    "canyon": "Canyon",
    "charlie": "Charlie’s Run",
    "cloud": "Cloud 9",
    "doe-run": "Doe Run",
    "drivets": "Drivets Drop",
    "fox-tail": "Fox Tail",
    "garden-state": "Garden State",
    "giant": "Giant Steps",
    "gorge": "Gorge Run",
    "grand-prix": "Grand Prix",
    "gran": "Granite Peak Quad",
    "great-north": "Great Northern",
    "halleys": "Halley’s Comet",
    "inde-pass": "Independence Pass",
    "ind-pass": "Indian Pass",
    "jumpin": "Jumpin Jack",
    "kami": "Kamikaze",
    "kyber": "Khyber Pass",
    "lark": "Lark",
    "Open": "Learning Area",
    "lift-line": "Lift Line",
    "lower-horizon": "Lower Horizon",
    "low-straight": "Lower Straightaway",
    "matchmaker": "Matchmaker",
    "osprey": "Osprey",
    "race": "Race Trail",
    "red-fox": "Red Fox",
    "red-tail": "Red Tail",
    "sayon": "Sayonara",
    "silver-fox": "Silver Fox",
    "sjourn-double": "Sojourn Double",
    "south-x": "South Peak Express",
    "sjourn": "Southern Sojourn",
    "straightaway": "Straightaway",
    "sugar-quad": "Sugar Quad",
    "sugar": "Sugar Slope",
    "tail": "Tail Spin",
    "cab": "The Cabriolet",
    "gully": "The Gully",
    "triple-bound": "Triple Bound",
    "twist": "Twist",
    "upper-horizon": "Upper Horizon",
    "vernon-triple": "Vernon Triple",
    "wacky": "Wacky Way",
    "zero": "Zero-G"
}